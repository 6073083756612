import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgCubo, ImgCubo} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import useSound from 'use-sound';
import {gsap} from "gsap";
import imgCubo from '../../../images/obras/obra-6/cubo.png';
import soundCubo from '../../../images/obras/obra-6/cubo.mp3';
import imgLeft from '../../../images/obras/obra-5/obra.jpeg';
import imgRight from '../../../images/obras/obra-7/obra.jpeg';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 7,
  bottom: null,
  left: 5,
};

const Page06Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedCubo, setInitAnimatedCubo] = useState(false);
  const [initsoundCubo, setInitsoundCubo] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-6/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 850, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchCubo(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchCubo = (noReplay = 1) => {
    // 'translate3d(-98.3871px, 196.774px, 0px) rotate(-59.0323deg)';
    // let el = document.getElementById('animate-cubo');
    // el.style.transform = 'translate3d(-98.3871px, 196.774px, 0px) rotate(-59.0323deg)';

    setInitAnimatedCubo(false);
    setInitsoundCubo(false);

    setTimeout(function (){
      setInitAnimatedCubo(true);
    }, 100);
  };

  const fnAnimateCubo = () => {
    return (
      <ContImgCubo className={'img-animated'} onClick={() => fnReLaunchCubo()}>
        {
          initAnimatedCubo &&
          <ImgCubo
            id={'animate-cubo'}
            className={'animate-cubo'}
            src={imgCubo}
            alt="Hombre"
          />
        }
      </ContImgCubo>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Del vómito a la sed,</div>
            <div>atado al potro del alcohol.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playCubo, { stopCubo }] = useSound(
    soundCubo,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedCubo(true);
    }, 3000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 6000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosCubo = {};

    console.log('winWidth ->', winWidth);

    if(winWidth < 767){
      arPosCubo = {x: -100, y: 200}
    } else if(winWidth < 1023){
      arPosCubo = {x: -160, y: 280}
    } else if(winWidth < 1215){
      arPosCubo = {x: -200, y: 350}
    } else if(winWidth < 1407){
      arPosCubo = {x: -100, y: 200}
    } else if(winWidth < 1919){
      arPosCubo = {x: -170, y: 330}
    } else {
      arPosCubo = {x: -200, y: 380}
    }

    if(initAnimatedCubo){
      /*gsap.fromTo(".animate-cubo", 2, {
        x: 10,
        y: 10,
      }, {
        x: -100,
        y: 200,
        duration: 3,
        delay: 2,
        rotation: function(i, target, list) {
          return -60;
        },
      }, "first");

      gsap.fromTo(".animate-cubo", 2, {
        x: -100,
        y: 200
      }, {
        x: 0,
        y: 0,
        duration: 3,
        delay: 4,
        rotation: function(i, target, list) {
          return 0;
        },
      }, "second");*/

      gsap.to('.animate-cubo', {
        x: arPosCubo.x,
        y: arPosCubo.y,
        delay: 1,
        duration:2,
        ease:"linear",
      });

      gsap.to('.animate-cubo', {
        x: 0,
        y: 0,
        delay: 5,
        duration:2,
        ease:"linear",
      });

      if(!initsoundCubo){
        playCubo();
        setInitsoundCubo(true);
      }
    }

  }, [initAnimatedCubo]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateCubo()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page06Comp;
