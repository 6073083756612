import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 290px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -155px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -100px;
      margin-left: 250px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -100px;
      margin-left: 345px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -100px;
      margin-left: 170px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 612px;
      height: 863px;
    }
    .speech-bubble-mini{
      margin-top: -130px;
      margin-left: 270px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 716px;
      height: 1010px;
    }
    .speech-bubble-mini{
      margin-top: -130px;
      margin-left: 310px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgCubo = styled.div`
  height: auto;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 145px;
    margin-top: -270px;
    margin-left: 53px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 282px;
    margin-top: -280px;
    margin-left: 107px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 380px;
    margin-top: -400px;
    margin-left: 145px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 200px;
    margin-top: -190px;
    margin-left: 75px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    margin-top: -270px;
    margin-left: 116px;
  }
  @media(min-width: 1920px){
    width: 350px;
    margin-top: -350px;
    margin-left: 135px;
  }
`
export const ImgCubo = styled.img`
  width: 100%;
  height: 100%;
`
